<template>
	<div class="padding-top-10 edit-contain">
		<top :bgWhite='true'></top>

		<div class="contents  padding-bottom-20 ">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/my' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item>职业认证</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="bg-white margin-top-20 padding-lr-50 padding-bottom-20">
				<div class="padding-top-20 fs24 solid-bottom padding-bottom-15 margin-bottom-20">
					认证名称
				</div>
				<el-input type="text" maxlength="10"  class="input  w100 line-eee  padding-tb-10 radius-2"
					v-model="title" placeholder="请输入名称(最多10个字符)" />
				<div class="  padding-top-20 fs24  padding-bottom-15">
					认证说明
				</div>
				<div class="flex align-center justify-center   height200">
					<el-input
					  type="textarea"
					  placeholder="请输入认证说明"
					  v-model="remark"
					  class=" w100 "
					  rows="8"
					  maxlength="200"
					  show-word-limit
					>
					</el-input>
					<!-- <quill-editor ref="text" v-model="form.content" class="myQuillEditor w100"
						:options="editorOption" /> -->
				</div>
				<div class="  margin-top-20 fs24  padding-bottom-15">
					上传图片
					<span class="text-999 fs12">（您的身份证明，最多上传3张图片）</span>
				</div>
				<el-upload
				  class="margin-top-30"
				  :action="BaseUrl+'/api/v1/5fc615f7da92b'"
				  list-type="picture-card"
				  :on-preview="handlePictureCardPreview"
				  :on-remove="handleRemove"
				  :on-change="handleChange"
				  :limit="3"
				  :file-list="fileList">
				  <i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
				<div class=" margin-top-70 margin-bottom-20 flex align-center justify-center">
					<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
						@click="submitForm()">提交</span>
				</div>
			</div>
			

		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'editArticle',
		components: {
			bottom,
			top,
			quillEditor
		},
		data() {
			return {
				dialogImageUrl: '',
				fileList:[],
				dialogVisible: false,
				BaseUrl:this.imgUrl,
				title:'', //认证名称
				remark: '', //认证名说明
				editorOption: {
					placeholder: "请输入正文"
				},
				imageUrl: '' //封面
			}
		},
		mounted() {
			this.type = this.$route.query.type
		},
		created(){
			
		},
		methods: {
			handleChange(file,fileList){
				this.fileList = fileList;
			},
			// 删除
			handleRemove(file,fileList) {
				this.fileList = fileList;
			},
			// 大图预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			// 上传成功回调
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			// 上传封面
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt10M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt10M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isLt10M;
			},
			submitForm() {
				if(!this.title){
					this.$message.error('请输入认证名称');
					return;
				};
				if(!this.remark){
					this.$message.error('请输入认证说明');
					return;
				};
				if(this.fileList.length==0){
					this.$message.error('请先上传认证图片');
					return;
				};
				let images = this.fileList.map(item=>{
					return item.response.data.data.fileId
				});
				let imgurl = '';
				if(images.length>0){
					 imgurl = images.join(',')
				};
				this.ajax('post', '/v1/61a0433fb10f4', {
					title:this.title,
					remark: this.remark ,
					image:imgurl
				}, res => {
					if (res.code == 1) {
						this.$message.success('提交认证成功');
						this.$router.go(-1);//返回上一层
					} else {
						this.$message.error(res.msg);
					}
				})
				// this.$router.push({
				// 	path: '/memberDate'
				// })
				// console.log(this.$refs.text.value)
			},
		}
	}
</script>

<style scoped>
	/deep/.el-input__inner{
		border: none;
	}
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.contents {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.myQuillEditor {
		height: 400px;
	}

	/deep/.el-form-item__label {
		padding-right: 30px;
	}

	/deep/.el-radio__inner {
		width: 20px;
		height: 20px;
	}

	.el-form-item {
		display: flex;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #E54F42;
		background: #E54F42;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}
</style>
